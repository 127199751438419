<template>
  <v-container class="pa-0 ma-0">
    <div class="d-flex cont-login">
      <div class="left-sc ma-0 pa-0">
        <v-img
          alt="Reclutachat Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="90"
          src="../../assets/logo/reclutachat-letters.png"
          width="300"
        />
      </div>

      <div
        class="right-sc d-flex flex-column align-center justify-center ma-0 pa-0"
      >
        <div
          v-if="loginStatusLoading === true"
          class="d-flex flex-column align-center justify-center loading"
        >
          <img src="../../assets/elements/loading.svg" />
          <p style="color: #a3a1fb; letter-spacing: 3px" class="mt-10">
            Validando información...
          </p>
        </div>
        <div v-else class="d-flex flex-column align-center" style="width: 100%">
          <v-img
            alt="Reclutachat logo"
            class="shrink hidden-sm-and-down"
            contain
            min-width="65"
            src="../../assets/logo/logo_icon.png"
            width="25"
          />
          <h1
            class="display-1 font-weight-black mt-6"
            style="letter-spacing: 3px; color: #6e80f8"
          >
            Bienvenido
          </h1>
          <v-card class="login-form mt-10" width="55%">
            <div
              class="d-flex flex-column justify-center align-center"
            >
              <v-text-field
                outlined
                rounded
                label="email"
                v-model="userCredentials.email"
                style="color: #8739eb; width: 70%;height: 70px"
                color="#8739EB"
                class="caption shrink mx-1 mt-3"
              >
                <template v-slot:label>
                  <div>
                    <v-icon
                      color="#8739EB"
                      small
                      class="pr-3"
                      style="vertical-align: center"
                      >mdi-email-outline</v-icon
                    >
                    <label class="caption pa-0 ma-0">email</label>

                  </div>
                </template>
              </v-text-field>
              <v-text-field
                outlined
                rounded
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPass ? 'text' : 'password'"
                @click:append="showPass = !showPass"
                v-model="userCredentials.password"
                style="color: #8739eb; width: 70%;height: 70px"
                color="#8739EB"
                class="input-group--focused body-1 shrink mx-1 caption"
              >
                <template v-slot:label >
                  <div>
                    <v-icon
                    color="#8739EB"
                    small
                    class="pr-3"
                    style="vertical-align: center"
                    >mdi-key-outline</v-icon
                    >
                    <label class="caption pa-0 ma-0">password</label>
                  </div>
                  
                </template>
              </v-text-field>
              <div class="b">
                <div class="butn-cont">
                  <v-btn
                    class="butn"
                    fab
                    elevation="0"
                    small
                    @click="login(userCredentials)"
                  >
                    <v-icon small color="#FFF"> mdi-arrow-right </v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </v-card>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import ENUM from "@/store/enums";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  data: () => ({
    showPass: false,
    userCredentials: {
      email: "",
      password: "",
    },
  }),
  computed: {
    ...mapState({
      loginStatus: state => state.auth.status
    }),
    loginStatusLoading() {
      return this.loginStatus === ENUM.LOADING;
    }
  },
  methods: {
    ...mapActions(["loginWithEmailAndPassword"]),
    ...mapMutations(["AUTH_STATUS_ERROR", "AUTH_STATUS_LOADING"]),
    login(userCredentials) {
      this.AUTH_STATUS_LOADING();
      this.loginWithEmailAndPassword(userCredentials)
      //We've removed the then that made the change in the router, we have to push
      //the route name after the onStateChanged returned the user info
      .catch((error) => {
        this.AUTH_STATUS_ERROR()
        console.log("error", error.code);
      });
    },
  },
};
</script>

<style>
.cont-login {
  width: 100vw;
}
.left-sc {
  width: 50vw;
  height: 100vh;
  background: transparent linear-gradient(180deg, #8739eb 0%, #2871fa 100%) 0%
    0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.right-sc {
  width: 50vw;
  height: 100vh;
  background-color: #eff1ff;
}
.login-form {
  box-shadow: 0px 5px 12px #00000014 !important;
}
.b {
  position: absolute;
  margin-top: 60%;
}
.butn-cont {
  border: 8px solid rgb(255, 255, 255);
  border-radius: 100%;
  box-shadow: 0px 3px 10px #00000014;
}
.butn {
  background: transparent linear-gradient(127deg, #8739eb 0%, #2871fa 100%) 0%
    0% no-repeat padding-box;
}
.loading {
  width: 100%;
  height: 100vh;
}
</style>
